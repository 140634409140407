<template>
  <!-- 内容 -->
  <div class="help_content">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goBackHome()">{{ lastPage=='lastPage'?'回UPlus':routePath == 'home'?'上一页':'帮助中心' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item v-if="routePath != 'home'" style="cursor: pointer"><span style="color: #AB54DB;" @click="goback()">公告中心</span></el-breadcrumb-item>
      <el-breadcrumb-item style="color: #72777E">详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="notice_list" style="margin-top: 30px; margin-bottom: 60px;">
      <span style="display: block">{{ noticeContent.title }}</span>
      <span style="display: block !important;margin-top: 8px;color: #72777E;">{{ noticeContent.send_time }}</span>
      <iframe v-if="noticeContent.content.indexOf('messageContent')>=0" style="width: 100%; min-height: 2000px; border: none; overflow: scroll; margin-top: 15px" :src="noticeContent.content.replace(/<[^>]+>/g,'')"></iframe>
<!--      <p v-else class="notice_content" v-html="noticeContent.content"></p>-->
      <p v-else class="notice_content" v-html="noticeContent.content.replaceAll('&lt;', '<').replaceAll('&gt;', '>')"></p>
    </div>
  </div>
</template>

<script>
import { getNoticeMsgById } from "../../server/helper.js"
import { getMsgById } from "../../server/message"
export default {
  name: "help_content_one",
  components: {},
  data(){
    return{
      noticeContent: {},
      id: '',
      routePath: '',
      lastPage: '',
      token: ''
    }
  },

  created() {

  },

  mounted() {

  },

  activated() {
    this.routePath = this.$route.query.home
    this.lastPage = this.$route.query.lastPage
    this.noticeContent = {}
    this.id = this.$route.query.noticeId
    this.token = localStorage.getItem('token')
    if(this.token){
      this.getMsgById()
    } else {
      this.getNoticeMsgById()
    }
  },

  methods: {
    getNoticeMsgById(){
      getNoticeMsgById(this.id).then(res =>{
        if(!res) return
        if(res.success){
          this.noticeContent = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    getMsgById(){
      getMsgById(this.id).then(res =>{
        if(!res) return
        if(res.success){
          this.noticeContent = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    goback(){
      this.$router.go(-1)
    },

    goBackHome(){
      if (this.routePath == 'home'){
        this.$router.go(-1)
      } else {
        this.$router.go(-2)
      }
    }
  }
}
</script>

<style scoped>
span{
  color: #1E2329;
}

@media screen and (max-width: 900px){
  .help_content {
    /*width: 100%;*/
    margin-top: -27px;
    min-height: 450px;
    padding: 0 16px
  }
  .content_list {
    column-count: 1;
  }
  .content_list span{
    color: #464255;
    font-size: 14px;
  }
  .content_title {
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    display: block;
  }
  .auto_list{
    column-count: 3;
    text-align: center;
  }
  .notice_list{
    column-count: 1;
    /*text-align: center;*/
  }
  .notice_list span:nth-child(1){
    font-size: 16px;
  }
  .notice_list span:nth-child(2){
    font-size: 12px;
  }
  .notice_content{
    font-size: 14px;
  }
  .el-container{
    display: block;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 90%;
    height: 230px;
    text-align: center;
  }

  .help_content_top_left{
    margin-top: 20px;
  }
  .help_content_bg{
    text-align: center;
  }
}
@media screen and (min-width: 900px){
  .content_list {
    /*column-count: 4;*/
  }

  .content_list span{
    display: inline-block !important;
    color: #464255;
    width: 30%;
  }

  .auto_list{
    column-count: 6;
    text-align: center;
  }

  .notice_list{
    /*column-count: 2;*/
    /*text-align: center;*/
  }

  .notice_list div{
    display: inline-block !important;
    width: 50%;
  }

  .notice_list span:nth-child(1){
    font-size: 16px;
  }
  .notice_list span:nth-child(2){
    font-size: 14px;
  }

  .help_content {
    width: 1200px;
    min-height: 795px;
    margin: 50px auto 0;
    padding: 0 16px
  }

  .content_title {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

  .help_content_top{
    background: #FFFFFF;
    width: 380px;
    height: 260px;
    margin-right: 30px;
  }

  .help_content_top_left{
    margin-left: 30px;
  }

  .help_content_bg{
    text-align: unset;
  }
}
</style>
